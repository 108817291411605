<template>
  <div
    v-if="activeSearch"
    class="search-container"
  >
    <div class="height-100">
      <div v-if="arrSearch.length == 0">
        <div class="display-flex align-items-center height-100 search-list">
          <p
            v-if="selectLanguage == 'sp'"
            class="search-text mon-regular ml-2"
          >
            No existen elementos relacionados con esta búsqueda.
          </p>
          <p
            v-else
            class="search-text mon-regular ml-2"
          >
            There no items related to this search.
          </p>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(item, index) in arrSearch"
          :key="index"
          class="display-flex align-items-center height-100 search-list"
          @click="redirectProducts(item.sProductId)"
        >
          <div class="content-image">
            <img
              v-if="Object.keys(item.aImages).length > 0"
              :src="item.aImages[0].oImages.sm"
              alt="proplat images"
            >
            <img
              v-else
              src="@/assets/images/placeholder-image.jpeg"
              alt="proplat-image"
            >
          </div>
          <div>
            <p class="search-text mon-regular ml-2">{{ item.sName }}</p>
            <p class="search-text-vendor-name mon-regular ml-2">{{ item.sVendorEnterpriseName }}</p>
          </div>
          <v-spacer />
          <v-icon
            color="#000"
            size="18px"
          >
            mdi-arrow-right
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "searchGlobalComponent",
  props: {
    sSearch: {
      type: String,
    },
    arrSearch: {
      type: Array,
    },
  },
  data() {
    return {
      //VARIABLES
      activeSearch: false,
    };
  },
  methods: {
    redirectProducts: function (id) {
      this.$router.push(`/view/article/${id}`).catch(() => {});
      this.activeSearch = false;
      this.$emit("clearSearch");
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    arrSearch: function () {
      if (this.arrSearch.length > 0) {
        this.activeSearch = true;
      } else {
        this.activeSearch = true;
      }
    },
    sSearch: function () {
      if (this.sSearch == "") {
        this.activeSearch = false;
      }
    },
  },
};
</script>

<style scope>
.search-container {
  background-color: #ffffff;
  width: calc(100% - 57px);
  position: absolute;
  border-radius: 10px;
  max-height: 420px;
  overflow: auto;
  z-index: 2;
}

.search-list {
  padding: 10px;
  border-radius: 10px;
}

.search-list:hover {
  cursor: pointer;
  background-color: #00000010;
}

.search-text {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: -5px !important;
}

.search-text-vendor-name {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d2770;
  opacity: 1;
  margin-bottom: 0px !important;
}

.content-image {
  width: 35px;
  height: 35px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 10px;
  }
}
</style>